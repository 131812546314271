import React from "react"

import { unixToDate } from "../../_helpers/date"

import "./item.scss"

const VideoItem = ({ URI, name, date, pictures, updatePlayer }) => {
    return (
        <>
            <div className="col-lg-6 video-item">
                <button
                    onClick={() => updatePlayer(URI, name)}
                    className="button-link"
                >
                    <img
                        src={pictures.sizes[3].link_with_play_button}
                        alt={name}
                    />
                    <h5>{name}</h5>
                    <span>{`Published: ${unixToDate(date)}`}</span>
                </button>
            </div>
        </>
    )
}

export default VideoItem
