import React from "react"

import "./player.scss"

const VideoPlayer = ({URI, name }) => {
    if(URI) {
        const videoID = URI.split("/").splice(-1)[0]
        return(
            <div className="col-12 video-player">
                <div>
                    <iframe title={name} src={`https://player.vimeo.com/video/${videoID}?autoplay=1`} frameBorder="0" allow="autoplay; fullscreen" allowFullScreen></iframe>
                </div>
            </div>
        )
    } else {
        return(null)
    }
    
}

export default VideoPlayer