import React from "react"
import axios from "axios"

import Loader from "../Loader"
import VideoPlayer from "../Video/player"
import VideoItem from "../Video/item"
import Pagination from "../Pagination"

const videosAPI = process.env.API_VIDEOS

class VideoList extends React.Component {
    constructor(props) {
        super(props)
        this.player = React.createRef()
        this.videoList = React.createRef()
        this.state = {
            videos: [],
            player: {},
            page: 1,
            perPage: 20,
            isLoading: true,
        }
    }

    componentDidMount = () => {
        const { page, perPage } = this.state
        this.getVideos(page, perPage)
    }

    getVideos = (page, perPage) => {
        this.setState({
            isLoading: true,
            videos: [],
        })
        axios
            .get(`${videosAPI}/?page=${page}&per-page=${perPage}`)
            .then((response) => {
                this.setState({
                    videos: response.data.data,
                    total: response.data.total,
                    page: page,
                    isLoading: false,
                })
            })
            .catch((error) => {
                this.setState({
                    message: {
                        type: "danger",
                        text: error.response.data.message,
                    },
                    isLoading: false,
                })
            })
    }

    updatePlayer = (URI, name) => {
        window.scrollTo({
            top: this.player.current.offsetTop - 60,
            left: 0,
            behavior: "smooth",
        })
        this.setState({
            player: {
                URI: URI,
                name: name,
            },
        })
    }

    updateVideoList = (page, perPage) => {
        window.scrollTo(0, this.videoList.current.offsetTop - 120)
        this.setState({
            player: {},
        })
        this.getVideos(page, perPage)
    }

    render() {
        const { videos, page, perPage, total, isLoading, player } = this.state
        let videoList
        if (videos) {
            videoList = videos.map((video, index) => (
                <VideoItem
                    key={index}
                    URI={video.uri}
                    name={video.name}
                    description={video.description}
                    date={video.created_time}
                    pictures={video.pictures}
                    updatePlayer={this.updatePlayer}
                />
            ))
        }
        if (isLoading === true) {
            return <Loader />
        } else {
            return (
                <>
                    <div className="row" ref={this.player}>
                        <VideoPlayer URI={player.URI} name={player.name} />
                    </div>
                    <div className="row" ref={this.videoList}>
                        {videos ? videoList : <p>No videos to display</p>}
                    </div>
                    <div className="row">
                        <Pagination
                            page={page}
                            perPage={perPage}
                            total={total}
                            updateFunc={this.updateVideoList}
                        />
                    </div>
                </>
            )
        }
    }
}

export default VideoList
